import React, { useEffect, useContext, useState, useMemo, useRef } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { SessionContext } from "../context/SessionContext";
import { ProfileContext } from "../context/ProfileContext";
import { getAddress, getFullName } from "../components/Utils";
import { v4 as uuidv4 } from "uuid";
import differenceInDays from "date-fns/differenceInDays";
import AsyncSelect from "react-select/async";
import toast from "react-hot-toast";
import ToastDisplay from "../components/ToastDisplay";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Pagination from "../components/Pagination";
import Spinner from "react-bootstrap/Spinner";
import ContentLoader from "../components/ContentLoader";
import FileUpload from "../components/FileUpload";
import Empty from "../components/Empty";

const WorkOrders = () => {
	document.title = "Work Orders | Repairs & Maintenance Management System";
	const fileRef = useRef(null);
	const navigate = useNavigate();
	const [session] = useContext(SessionContext);
	const [profile] = useContext(ProfileContext);
	const [workOrders, setWorkOrders] = useState([]);
	const [meta, setMeta] = useState(null);
	const [loading, setLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [workOrderModal, setWorkOrderModal] = useState(false);
	const [filterModal, setFilterModal] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const [errors, setErrors] = useState([]);

	const [shortDesc, setShortDesc] = useState("");
	const [desc, setDesc] = useState("");
	const [type, setType] = useState("Corrective");
	const [category, setCategory] = useState("");
	const [receivedBy, setReceivedBy] = useState("");
	const [assignedTo, setAssignedTo] = useState("");
	const [serviceReportNo, setServiceReportNo] = useState("");
	const [invoiceNo, setInvoiceNo] = useState("");
	const [sapDocNo, setSapDocNo] = useState("");
	const [woDate, setWoDate] = useState("");

	const [files, setFiles] = useState([]);
	const [filePaths, setFilePaths] = useState([]);

	const [client, setClient] = useState(null);
	const [clientLoading, setClientLoading] = useState(false);
	const [clientInput, setClientInput] = useState("");
	const [selectedClient, setSelectedClient] = useState(null);

	const [searchParams, setSearchParams] = useSearchParams({});
	const [searchQ, setSearchQ] = useState(searchParams.get("q") || "");
	const [searchStatus, setSearchStatus] = useState(
		searchParams.get("status") || ""
	);
	const [searchType, setSearchType] = useState(searchParams.get("type") || "");
	const [searchPriority, setSearchPriority] = useState(
		searchParams.get("priority") || ""
	);
	const [searchCategory, setSearchCategory] = useState(
		searchParams.get("category") || ""
	);
	const [searchFrom, setSearchFrom] = useState(searchParams.get("from") || "");
	const [searchTo, setSearchTo] = useState(searchParams.get("to") || "");

	const [searchClient, setSearchClient] = useState("");
	const [searchClientLoading, setSearchClientLoading] = useState(false);
	const [searchClientInput, setSearchClientInput] = useState("");
	const [searchSelectedClient, setSearchSelectedClient] = useState(null);

	const [searchContractor, setSearchContractor] = useState("");
	const [searchContractorLoading, setSearchContractorLoading] = useState(false);
	const [searchContractorInput, setSearchContractorInput] = useState("");
	const [searchSelectedContractor, setSearchSelectedContractor] =
		useState(null);

	const searchFields = [
		{ key: "q", value: searchQ },
		{ key: "status", value: searchStatus },
		{ key: "type", value: searchType },
		{ key: "priority", value: searchPriority },
		{ key: "category", value: searchCategory },
		{ key: "client", value: searchClient },
		{ key: "contractor", value: searchContractor },
		{ key: "from", value: searchFrom },
		{ key: "to", value: searchTo },
	];

	const filterFields = [
		{
			key: "status",
			value: searchStatus,
			display: searchStatus,
			defaultVal: "",
			edit: setSearchStatus,
		},
		{
			key: "type",
			value: searchType,
			display: searchType,
			defaultVal: "",
			edit: setSearchType,
		},
		{
			key: "priority",
			value: searchPriority,
			display: searchPriority,
			defaultVal: "",
			edit: setSearchPriority,
		},
		{
			key: "category",
			value: searchCategory,
			display: searchCategory,
			defaultVal: "",
			edit: setSearchCategory,
		},
		{
			key: "client",
			value: searchClient,
			display: searchSelectedClient?.label,
			defaultVal: "",
			edit: setSearchSelectedClient,
		},
		{
			key: "contractor",
			value: searchContractor,
			display: searchSelectedContractor?.label,
			defaultVal: "",
			edit: setSearchSelectedContractor,
		},
		{
			key: "from",
			value: searchFrom,
			display: searchFrom,
			defaultVal: "",
			edit: setSearchFrom,
		},
		{
			key: "to",
			value: searchTo,
			display: searchTo,
			defaultVal: "",
			edit: setSearchTo,
		},
	];

	const requiredFields = [
		{ key: "shortDesc", value: shortDesc },
		{ key: "desc", value: desc },
		{ key: "woDate", value: woDate },
		{ key: "type", value: type },
	];

	const getCurrentDate = () => {
		const date = new Date();
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");
		return `${year}-${month}-${day}`;
	};

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/work-orders/${window.location.search}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setWorkOrders(data.data);
			setMeta(data.meta);
		}
		setLoading(false);
	};

	const getClient = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/c/${selectedClient.value}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setClient(data.client);
		}
	};

	const saveWorkOrder = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
			body: JSON.stringify({
				short_description: shortDesc,
				description: desc,
				type: type,
				category: category,
				received_by: receivedBy,
				assigned_to: assignedTo,
				service_report_no: serviceReportNo,
				invoice_no: invoiceNo,
				sap_doc_no: sapDocNo,
				wo_date: woDate,
				client_id: client.id,
				attachments: filePaths,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/work-orders/`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			closeWorkOrderModal();
			navigate(`/property/work-orders/${data.work_order_id}`);
		}
		setActionLoading(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredFields.map((field) => {
			if (!field.value || field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			toast(
				<ToastDisplay
					status="error"
					content="Please fill out all required fields."
				/>
			);
			setActionLoading(false);
			setErrors(newErrors);
			return;
		}
		saveWorkOrder();
	};

	const loadClientOptions = async (inputValue) => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/?q=${inputValue}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			return data.data?.map((client) => ({
				value: client.id,
				label: getFullName(client),
			}));
		}
	};

	const loadContractorOptions = async (inputValue) => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/contractors/?q=${inputValue}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			return data.data?.map((contractor) => ({
				value: contractor.id,
				label: contractor.company,
			}));
		}
	};

	const search = (e) => {
		e.preventDefault();
		var newSearch = { ...searchParams };
		searchFields.map((field) => {
			if (field.value !== "") {
				newSearch[field.key] = field.value;
			} else {
				delete newSearch[field.key];
			}
		});
		setSearchParams(newSearch);
		setFilterModal(false);
	};

	const removeFilter = (e, func, key) => {
		e.preventDefault();
		func("");
		var newSearch = { ...searchParams };
		searchFields.map((field) => {
			if (field.value !== "" && field.key !== key) {
				newSearch[field.key] = field.value;
			} else {
				delete newSearch[field.key];
			}
		});
		setSearchParams(newSearch);
	};

	const clearFilters = (e) => {
		e.preventDefault();
		setSearchParams({});
		filterFields.map((field) => {
			field.edit(field.defaultVal);
		});
	};

	const closeWorkOrderModal = () => {
		setWorkOrderModal(false);
		setDesc("");
		// setType("");
		setCategory("");
		setReceivedBy("");
		setAssignedTo("");
		setServiceReportNo("");
		setInvoiceNo("");
		setSapDocNo("");
		setWoDate(getCurrentDate());

		setClient(null);
		setSelectedClient(null);
		setClientInput("");

		setErrors([]);
	};

	const handleFiles = (e) => {
		const newFiles = Array.from(e.target.files);
		const forUpload = [...files];
		const paths = [...filePaths];

		newFiles.map((file) => {
			const newUid = uuidv4();
			forUpload.push({
				uuid: `${newUid}.${file.name.split(".").pop()}`,
				file: file,
			});
			paths.push(`${newUid}.${file.name.split(".").pop()}`);
		});

		setFiles(forUpload);
		setFilePaths(paths);
	};

	const isEmpty = (value) => {
		return value === "" || value === null || value === undefined;
	};

	useEffect(() => {
		if (profile) {
			if (!profile.role.work_orders_view) {
				navigate("/");
			} else {
				getData();
				setWoDate(getCurrentDate());
				if (filterFields.every((field) => isEmpty(field.value))) {
					setShowFilters(false);
				} else {
					setShowFilters(true);
				}
			}
		}
	}, [searchParams, profile]);

	useMemo(() => {
		if (selectedClient) {
			getClient();
		}
	}, [selectedClient]);

	useMemo(() => {
		if (searchSelectedClient) {
			setSearchClient(searchSelectedClient.value);
		} else {
			setSearchClient("");
		}
	}, [searchSelectedClient]);

	useMemo(() => {
		if (searchSelectedContractor) {
			setSearchContractor(searchSelectedContractor.value);
		} else {
			setSearchContractor("");
		}
	}, [searchSelectedContractor]);

	return (
		<>
			<Container className="gx-5">
				<Row className="content-header flex-row-left mb-30">
					<Col>
						<h1 className="text-xl m-0">Work Orders</h1>
					</Col>
				</Row>
				<Row className="flex-row-left mb-20">
					<Col className="col-5">
						<Form onSubmit={search}>
							<div className="search-container bg-white flex-row-left">
								<i className="ri-search-line search-icon" />
								<Form.Control
									className="text-sm"
									placeholder="Search work orders"
									value={searchQ}
									onChange={(e) => setSearchQ(e.target.value)}
								/>
								<Button
									variant="light"
									className="filter-btn flex-column"
									onClick={() => setFilterModal(true)}
								>
									<i className="ri-equalizer-3-fill m-0" />
								</Button>
							</div>
						</Form>
					</Col>
					<Col className="col-7 flex-row-right">
						<Pagination
							meta={meta}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							searchFields={searchFields}
							className="pull-right flex-row-left"
						/>
						<Button
							variant="light"
							className="refresh-btn flex-column"
							onClick={getData}
						>
							<i className="ri-restart-line opacity-7" />
						</Button>
						{profile?.role?.work_orders_add && (
							<Button
								className="control-btn btn-sm btn-default text-medium-gotham text-sm"
								onClick={() => setWorkOrderModal(true)}
							>
								Add Work Order
							</Button>
						)}
					</Col>
				</Row>
				{showFilters ? (
					<div className="filters-container">
						<span className="filters-label text-medium-mont">Filters:</span>
						{filterFields.map((field) => {
							const { key, value, display } = field;
							if (value) {
								return (
									<div
										key={key}
										className="filter-item bg-default flex-row-left rounded-4"
									>
										<span className="text-white label-md mb-0">
											{key.charAt(0).toUpperCase() + key.slice(1)}: {display}
										</span>
										<a
											href="#"
											className="remove-filter"
											onClick={(e) => removeFilter(e, field.edit, key)}
										>
											<i className="ri-close-line text-white" />
										</a>
									</div>
								);
							} else {
								return "";
							}
						})}
						<div className="filter-item rounded-4 fit">
							<a
								href="#"
								className="text-sm text-medium-mont text-default"
								onClick={clearFilters}
							>
								Clear Filters
							</a>
						</div>
					</div>
				) : (
					""
				)}
				<Row className="content-table-header bg-white gx-0">
					<Col className="p-10 col-2">
						<span className="text-sm text-medium-gotham block">
							Work Order #
						</span>
					</Col>
					<Col className="p-10">
						<span className="text-sm text-medium-gotham block">Client</span>
					</Col>
					<Col className="p-10">
						<span className="text-sm text-medium-gotham block">
							Short Description
						</span>
					</Col>
					<Col className="p-10">
						<span className="text-sm text-medium-gotham block">Aging</span>
					</Col>
					<Col className="p-10">
						<span className="text-sm text-medium-gotham block">Status</span>
					</Col>
				</Row>
				{loading ? (
					<ContentLoader />
				) : workOrders?.length === 0 ? (
					<Empty label="No work orders" className="bg-white" />
				) : (
					workOrders?.map((wo) => (
						<Link key={wo.id} to={`/property/work-orders/${wo.id}`}>
							<Row className="content-table-item bg-white gx-0 flex-row-left">
								<Col className="p-10 ellipsis col-2">
									<span className="text-sm">{wo.wo_number}</span>
								</Col>
								<Col className="p-10 ellipsis">
									<span className="text-sm ellipsis">
										{getFullName(wo.client) || "--"}
									</span>
								</Col>
								<Col className="p-10 ellipsis">
									<span className="text-sm ellipsis">
										{wo.short_description || "--"}
									</span>
								</Col>
								<Col className="p-10 ellipsis">
									<span className="text-sm">
										{wo.received
											? differenceInDays(new Date(), new Date(wo.received))
											: 0}
									</span>
								</Col>
								<Col className="p-10">
									<div className={`wo-status fit flex-column ${wo.status}`}>
										<span className="label-md text-medium-gotham">
											{wo.status || "--"}
										</span>
									</div>
								</Col>
							</Row>
						</Link>
					))
				)}
				<Row className="mt-4 mb-20">
					<Col className="flex-row-right">
						<Pagination
							meta={meta}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							searchFields={searchFields}
							className="pull-right flex-row-left pagination-bottom"
						/>
					</Col>
				</Row>
			</Container>
			<Modal
				show={filterModal}
				centered
				onHide={() => setFilterModal(false)}
				className="filter-modal"
			>
				<Form onSubmit={search}>
					<Modal.Header>
						<Modal.Title className="text-lg">Filters</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">From Date</Form.Label>
								<Form.Control
									type="date"
									className="text-sm form-input"
									value={searchFrom}
									onChange={(e) => setSearchFrom(e.target.value)}
								/>
							</Col>
							<Col>
								<Form.Label className="text-sm">To Date</Form.Label>
								<Form.Control
									type="date"
									className="text-sm form-input"
									value={searchTo}
									onChange={(e) => setSearchTo(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Client</Form.Label>
								<AsyncSelect
									isClearable
									isDisabled={searchClientLoading}
									isLoading={searchClientLoading}
									inputValue={searchClientInput}
									onInputChange={setSearchClientInput}
									noOptionsMessage={() =>
										searchClientInput ? "No results" : "Search clients"
									}
									loadOptions={loadClientOptions}
									defaultOptions={[]}
									onChange={setSearchSelectedClient}
									value={searchSelectedClient}
									menuShouldScrollIntoView={false}
									maxMenuHeight={200}
									// onCreateOption={createClient}
									placeholder=""
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											borderColor: state.isFocused
												? "#c7883a !important"
												: "#dee2e6 !important",
											boxShadow: "none",
											outline: "none",
											borderRadius: 4,
											fontSize: "0.875rem",
										}),
										option: (baseStyles, state) => ({
											...baseStyles,
											fontSize: ".875rem",
											color: "#050911",
											backgroundColor: state.isFocused
												? "#f6d4aa !important"
												: "#fff",
										}),
									}}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Contractor</Form.Label>
								<AsyncSelect
									isClearable
									isDisabled={searchContractorLoading}
									isLoading={searchContractorLoading}
									inputValue={searchContractorInput}
									onInputChange={setSearchContractorInput}
									noOptionsMessage={() =>
										searchContractorInput ? "No results" : "Search contractors"
									}
									loadOptions={loadContractorOptions}
									defaultOptions={[]}
									onChange={setSearchSelectedContractor}
									value={searchSelectedContractor}
									menuShouldScrollIntoView={false}
									maxMenuHeight={200}
									// onCreateOption={createClient}
									placeholder=""
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											borderColor: state.isFocused
												? "#c7883a !important"
												: "#dee2e6 !important",
											boxShadow: "none",
											outline: "none",
											borderRadius: 4,
											fontSize: "0.875rem",
										}),
										option: (baseStyles, state) => ({
											...baseStyles,
											fontSize: ".875rem",
											color: "#050911",
											backgroundColor: state.isFocused
												? "#f6d4aa !important"
												: "#fff",
										}),
									}}
								/>
							</Col>
						</Row>
						{/* <Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Type</Form.Label>
								<Form.Select
									className="text-sm form-input"
									value={searchType}
									onChange={(e) => setSearchType(e.target.value)}
								>
									<option value=""></option>
									<option value="Preventive Maintenance">
										Preventive Maintenance
									</option>
									<option value="Corrective Maintenance">
										Corrective Maintenance
									</option>
								</Form.Select>
							</Col>
						</Row> */}
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Category</Form.Label>
								<Form.Select
									className="text-sm form-input"
									value={searchCategory}
									onChange={(e) => setSearchCategory(e.target.value)}
								>
									<option value=""></option>
									<option value="Electrical">Electrical</option>
									<option value="Plumbing">Plumbing</option>
								</Form.Select>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Priority</Form.Label>
								<Form.Select
									className="text-sm form-input"
									value={searchPriority}
									onChange={(e) => setSearchPriority(e.target.value)}
								>
									<option value=""></option>
									<option value="High">High</option>
									<option value="Medium">Medium</option>
									<option value="Low">Low</option>
								</Form.Select>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Status</Form.Label>
								<Form.Select
									className="text-sm form-input"
									value={searchStatus}
									onChange={(e) => setSearchStatus(e.target.value)}
								>
									<option value=""></option>
									<option value="New">New</option>
									<option value="Completed">Completed</option>
								</Form.Select>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={() => setFilterModal(false)}
						>
							Close
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn text-sm btn-default"
						>
							Apply
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={workOrderModal}
				className="info-modal"
				centered
				size="lg"
				onHide={closeWorkOrderModal}
			>
				<Form onSubmit={handleSubmit}>
					<Modal.Header>
						<Modal.Title className="text-lg">New Work Order</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">
								Work Order Information
							</h2>
						</div>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Short Description</Form.Label>
								<Form.Control
									autoFocus
									className={`text-sm form-input ${
										errors.includes("shortDesc") && "border-danger"
									}`}
									value={shortDesc}
									onChange={(e) => setShortDesc(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Date</Form.Label>
								<Form.Control
									type="date"
									className={`text-sm form-input ${
										errors.includes("woDate") && "border-danger"
									}`}
									value={woDate}
									onChange={(e) => setWoDate(e.target.value)}
								/>
							</Col>
							{/* <Col>
								<Form.Label className="text-sm">Type</Form.Label>
								<Form.Select
									className={`text-sm form-input ${
										errors.includes("type") && "border-danger"
									}`}
									value={type}
									onChange={(e) => setType(e.target.value)}
								>
									<option value=""></option>
									<option value="Preventive Maintenance">
										Preventive Maintenance
									</option>
									<option value="Corrective Maintenance">
										Corrective Maintenance
									</option>
								</Form.Select>
							</Col> */}
						</Row>
						<Row className="mb-20">
							<Col>
								<Form.Label className="text-sm">Category</Form.Label>
								<Form.Select
									className={`text-sm form-input ${
										errors.includes("category") && "border-danger"
									}`}
									value={category}
									onChange={(e) => setCategory(e.target.value)}
								>
									<option value=""></option>
									<option value="Electrical">Electrical</option>
									<option value="Plumbing">Plumbing</option>
								</Form.Select>
							</Col>
						</Row>
						<Row className="mb-50">
							<Col>
								<Form.Label className="text-sm">Description</Form.Label>
								<Form.Control
									as="textarea"
									className={`text-sm form-input ${
										errors.includes("desc") && "border-danger"
									}`}
									value={desc}
									onChange={(e) => setDesc(e.target.value)}
								/>
							</Col>
						</Row>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">
								Client Information
							</h2>
						</div>
						<div className="bg-gray rounded-8 p-20 mb-50">
							<Row className="mb-20">
								<Col>
									<Form.Label className="text-sm">Client</Form.Label>
									<AsyncSelect
										isClearable
										isDisabled={clientLoading}
										isLoading={clientLoading}
										inputValue={clientInput}
										onInputChange={setClientInput}
										noOptionsMessage={() =>
											clientInput ? "No results" : "Search clients"
										}
										loadOptions={loadClientOptions}
										defaultOptions={[]}
										onChange={setSelectedClient}
										value={selectedClient}
										menuShouldScrollIntoView={false}
										maxMenuHeight={200}
										// onCreateOption={createClient}
										placeholder=""
										styles={{
											control: (baseStyles, state) => ({
												...baseStyles,
												borderColor: state.isFocused
													? "#c7883a !important"
													: "#dee2e6 !important",
												boxShadow: "none",
												outline: "none",
												borderRadius: 4,
												fontSize: "0.875rem",
											}),
											option: (baseStyles, state) => ({
												...baseStyles,
												fontSize: ".875rem",
												color: "#050911",
												backgroundColor: state.isFocused
													? "#f6d4aa !important"
													: "#fff",
											}),
										}}
									/>
								</Col>
							</Row>
							<Row className="mb-20">
								<Col>
									<Form.Label className="text-sm">Agency</Form.Label>
									<span className="text-sm block">
										{client?.agency || "--"}
									</span>
								</Col>
								<Col>
									<Form.Label className="text-sm">Position</Form.Label>
									<span className="text-sm block">
										{client?.position || "--"}
									</span>
								</Col>
							</Row>
							<Row className="mb-20">
								<Col>
									<Form.Label className="text-sm">Address</Form.Label>
									<span className="text-sm block">
										{client?.address ? getAddress(client) : "--"}
									</span>
								</Col>
								<Col>
									<Form.Label className="text-sm">Email</Form.Label>
									<span className="text-sm block">{client?.email || "--"}</span>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Label className="text-sm">Contact No.</Form.Label>
									{client?.home_phone && (
										<span className="text-sm block">
											Home: {client?.home_phone}
										</span>
									)}
									{client?.work_phone && (
										<span className="text-sm block">
											Work: {client?.work_phone}
										</span>
									)}
									{client?.local_ext && (
										<span className="text-sm block">
											Ext: {client?.local_ext}
										</span>
									)}
									{client?.fax && (
										<span className="text-sm block">Fax: {client?.fax}</span>
									)}
									{client?.msisdn && (
										<span className="text-sm block">
											Mobile: {client?.msisdn}
										</span>
									)}

									{!client?.home_phone &&
									!client?.work_phone &&
									!client?.msisdn ? (
										<span className="text-sm block">--</span>
									) : (
										""
									)}
								</Col>
							</Row>
						</div>
						<div className="mb-10">
							<h2 className="text-lg text-medium-gotham mb-0">Attachments</h2>
						</div>
						<Row className="mb-20">
							<div className="order-files-container">
								{files?.map((file, index) => (
									<FileUpload
										key={index}
										index={index}
										file={file}
										files={files}
										setFiles={setFiles}
										filePaths={filePaths}
										setFilePaths={setFilePaths}
									/>
								))}
								<Button
									variant="light"
									className="file-btn flex-column border"
									onClick={() => fileRef.current.click()}
								>
									<i className="ri-upload-line opacity-7" />
								</Button>
								<input
									ref={fileRef}
									type="file"
									multiple
									className="hidden"
									accept="image/*"
									onChange={handleFiles}
								/>
							</div>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={closeWorkOrderModal}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn flex-column text-sm btn-default"
							disabled={actionLoading}
						>
							{actionLoading ? (
								<Spinner className="btn-loader text-white" />
							) : (
								"Save"
							)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default WorkOrders;
