import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ClientContext } from "../context/ClientContext";
import { toast, Toaster } from "react-hot-toast";
import ToastDisplay from "../components/ToastDisplay";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Logo from "../assets/hero.png";

const ClientLogin = () => {
	document.title = "Log in | RMMS - Repairs & Maintenance Management System";
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const [clientSession, setClientSession] = useContext(ClientContext);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);

	const authUser = async (e) => {
		e.preventDefault();
		setLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email: email,
				password: password,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/login`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />, {
				position: "top-center",
			});
			setPassword("");
		} else {
			setClientSession(data.client_session);
		}
		setLoading(false);
	};

	useEffect(() => {
		if (clientSession) {
			if (searchParams.has("r")) {
				navigate(searchParams.get("r"));
			} else {
				navigate("/");
			}
		}
	}, [, clientSession]);

	return (
		<div className="full-page bg-white flex-column">
			<Toaster
				position="bottom-right"
				toastOptions={{ className: "snackbar-container" }}
			/>
			<Container className="flex-column">
				<div className="login-form-container mb-50 bg-white col-xl-4 col-lg-6 col-md-7 col-12">
					<Form onSubmit={authUser} className="login-form full flex-column">
						<img src={Logo} alt="login-logo" className="login-logo mb-30" />
						<div className="align-left full mb-30">
							<h1 className="text-medium-gotham text-xl mb-0">Sign In</h1>
							<span className="login-title text-md">
								Repairs & Maintenance Management System
							</span>
						</div>
						<Form.Control
							type="email"
							className="login-input rounded-4 mb-20"
							placeholder="Username/Email"
							autoCapitalize="off"
							autoComplete="off"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<Form.Control
							type="password"
							className="login-input rounded-4 mb-30"
							placeholder="Password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<Button
							type="submit"
							className="btn-default login-btn btn-md text-sm text-medium-inter full mb-50"
							disabled={loading}
						>
							{loading ? "Signing you in.." : "Sign in"}
						</Button>
					</Form>
				</div>
			</Container>
		</div>
	);
};

export default ClientLogin;
