import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SessionContext } from "../context/SessionContext";
import { ProfileContext } from "../context/ProfileContext";
import { WithContext as ReactTags } from "react-tag-input";
import { getFullName } from "../components/Utils";
import Select from "react-select";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Skeleton from "react-loading-skeleton";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import toast from "react-hot-toast";
import ToastDisplay from "../components/ToastDisplay";
import ClientDetails from "../components/ClientDetails";
import InfoLogs from "../components/InfoLogs";

import VillageOptions from "../components/VillageOptions";
import AgencyOptions from "../components/AgencyOptions";
import ClientPartnerDetails from "../components/ClientPartnerDetails";

const Client = () => {
	let { id } = useParams();
	const navigate = useNavigate();
	const villages = VillageOptions();
	const agencies = AgencyOptions();
	const [session] = useContext(SessionContext);
	const [profile] = useContext(ProfileContext);
	const [loading, setLoading] = useState(true);
	const [client, setClient] = useState(null);
	const [editModal, setEditModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [tab, setTab] = useState("details");
	const [infoTab, setInfoTab] = useState("details");
	const [passwordModal, setPasswordModal] = useState(false);

	const [prefix, setPrefix] = useState("");
	const [firstName, setFirstName] = useState("");
	const [middleName, setMiddleName] = useState("");
	const [lastName, setLastName] = useState("");
	const [suffix, setSuffix] = useState("");
	const [gender, setGender] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const [homePhone, setHomePhone] = useState("");
	const [msisdn, setMsisdn] = useState("");
	const [address, setAddress] = useState("");
	const [village, setVillage] = useState("");
	const [agency, setAgency] = useState("");
	const [position, setPosition] = useState("");
	const [workPhone, setWorkPhone] = useState("");
	const [localExt, setLocalExt] = useState("");
	const [fax, setFax] = useState("");
	const [remarks, setRemarks] = useState("");

	const [partnerPrefix, setPartnerPrefix] = useState("");
	const [partnerFirstName, setPartnerFirstName] = useState("");
	const [partnerMiddleName, setPartnerMiddleName] = useState("");
	const [partnerLastName, setPartnerLastName] = useState("");
	const [partnerSuffix, setPartnerSuffix] = useState("");
	const [partnerEmails, setPartnerEmails] = useState([]);
	const [partnerMsisdn, setPartnerMsisdn] = useState("");

	const [removePartnerEmails, setRemovePartnerEmails] = useState([]);
	const [errors, setErrors] = useState([]);

	const requiredFields = [
		{ key: "firstName", value: firstName },
		{ key: "lastName", value: lastName },
		{ key: "email", value: email },
	];

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/c/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setClient(data.client);
			setPartnerEmails(
				data.client.partner_emails.map((email) => ({
					id: email.id,
					text: email.email,
				}))
			);
			setEmail(data.client.email);
			setPrefix(data.client.prefix);
			setFirstName(data.client.first_name);
			setMiddleName(data.client.middle_name);
			setLastName(data.client.last_name);
			setSuffix(data.client.suffix);
			setGender(data.client.gender);
			setHomePhone(data.client.home_phone);
			setMsisdn(data.client.msisdn);
			setAddress(data.client.address);
			setVillage({ value: data.client.village, label: data.client.village });
			setAgency({ value: data.client.agency, label: data.client.agency });
			setPosition(data.client.position);
			setWorkPhone(data.client.work_phone);
			setLocalExt(data.client.local_ext);
			setFax(data.client.fax);
			setRemarks(data.client.remarks);

			setPartnerPrefix(data.client.partner_prefix);
			setPartnerFirstName(data.client.partner_first_name);
			setPartnerMiddleName(data.client.partner_middle_name);
			setPartnerLastName(data.client.partner_last_name);
			setPartnerSuffix(data.client.partner_suffix);
			setPartnerMsisdn(data.client.partner_msisdn);
		}
		setLoading(false);
	};

	const editClient = async () => {
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
			body: JSON.stringify({
				prefix: prefix,
				first_name: firstName,
				middle_name: middleName,
				last_name: lastName,
				suffix: suffix,
				gender: gender,
				email: email,
				password: password,
				home_phone: homePhone,
				msisdn: msisdn,
				address: address,
				village: village?.value || "",
				agency: agency?.value || "",
				position: position,
				work_phone: workPhone,
				local_ext: localExt,
				fax: fax,
				remarks: remarks,
				partner: {
					prefix: partnerPrefix,
					first_name: partnerFirstName,
					middle_name: partnerMiddleName,
					last_name: partnerLastName,
					suffix: partnerSuffix,
					emails: partnerEmails,
					msisdn: partnerMsisdn,
					remove_emails: removePartnerEmails,
				},
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/c/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			getData();
			closeEditModal();
		}
		setActionLoading(false);
	};

	const resetPassword = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
			body: JSON.stringify({
				password: password,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/c/${id}/password/reset`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			toast(<ToastDisplay status="error" content={data.msg} />);
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			closePasswordModal();
		}
		setActionLoading(false);
	};

	const deleteClient = async () => {
		setDeleteLoading(true);
		const requestOptions = {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/c/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			toast(<ToastDisplay status="success" content={data.msg} />);
			navigate("/property/clients");
			setDeleteModal(false);
		}
		setDeleteLoading(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredFields.map((field) => {
			if (!field.value || field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			toast(
				<ToastDisplay
					status="error"
					content="Please fill out all required fields."
				/>
			);
			setActionLoading(false);
			setErrors(newErrors);
			return;
		}
		editClient();
	};

	const closePasswordModal = () => {
		setPasswordModal(false);
		setPassword("");
	};

	const closeEditModal = () => {
		setEditModal(false);
		setPartnerEmails(
			client?.partner_emails.map((email) => ({
				id: email.id,
				text: email.email,
			}))
		);
		setEmail(client.email);
		setPrefix(client.prefix);
		setFirstName(client.first_name);
		setMiddleName(client.middle_name);
		setLastName(client.last_name);
		setSuffix(client.suffix);
		setGender(client.gender);
		setHomePhone(client.home_phone);
		setMsisdn(client.msisdn);
		setAddress(client.address);
		setVillage({ value: client.village, label: client.village });
		setAgency({ value: client.agency, label: client.agency });
		setPosition(client.position);
		setWorkPhone(client.work_phone);
		setLocalExt(client.local_ext);
		setFax(client.fax);
		setRemarks(client.remarks);

		setPartnerPrefix(client.partner_prefix);
		setPartnerFirstName(client.partner_first_name);
		setPartnerMiddleName(client.partner_middle_name);
		setPartnerLastName(client.partner_last_name);
		setPartnerSuffix(client.partner_suffix);
		setPartnerMsisdn(client.partner_msisdn);

		setInfoTab("details");
		setRemovePartnerEmails([]);
		setErrors([]);
	};

	const KeyCodes = {
		comma: 188,
		enter: 13,
		space: 32,
	};

	const emailDelimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space];

	const handleDeletePartnerEmail = (i) => {
		setRemovePartnerEmails([
			...removePartnerEmails,
			partnerEmails.filter((tag, index) => index === i)[0],
		]);
		setPartnerEmails(partnerEmails.filter((tag, index) => index !== i));
	};

	const handleNewPartnerEmail = (tag) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (emailRegex.test(tag.text)) {
			setPartnerEmails([...partnerEmails, tag]);
		} else {
			toast(<ToastDisplay status="error" content="Invalid email format." />);
		}
	};

	const handlePartnerEmailBlur = (tag) => {
		if (tag) {
			handleNewPartnerEmail({ id: tag, text: tag });
		}
	};

	useEffect(() => {
		if (profile) {
			if (!profile.role.clients_view) {
				navigate("/");
			} else {
				if (id) {
					getData();
				}
			}
		}
	}, [id, profile]);

	return (
		<>
			<Container className="gx-5">
				<div className="info-page-header mb-10">
					<a
						href="#"
						className="back-btn flex-row-left fit mb-20"
						onClick={(e) => {
							e.preventDefault();
							navigate(-1);
						}}
					>
						<i className="ri-arrow-left-line opacity-7" />
						<span className="text-sm text-regular-inter upper opacity-7">
							Clients
						</span>
					</a>
					<Row className="flex-row-left">
						<Col className="flex-row-left">
							<div className="contact-icon flex-column rounded-8">
								<span className="text-bold-gotham text-md">
									{client?.first_name[0]}
									{client?.last_name[0]}
								</span>
							</div>
							<h1 className="text-lg mb-0 text-medium-gotham">
								{loading ? (
									<Skeleton
										width={250}
										height={25}
										baseColor="#dadee7"
										highlightColor="#e9ecf2"
									/>
								) : (
									getFullName(client) || "--"
								)}
							</h1>
						</Col>
						<Col className="flex-row-right">
							{profile?.role?.clients_edit || profile?.role?.clients_delete ? (
								<Dropdown>
									<Dropdown.Toggle
										variant="light"
										className="info-control-btn text-lg rounded-4 flex-column no-arrow"
										disabled={loading}
									>
										<i className="ri-more-2-fill me-0" />
									</Dropdown.Toggle>
									<Dropdown.Menu className="text-sm dropdown-default">
										{profile?.role?.clients_edit && (
											<>
												<Dropdown.Item
													href="#"
													onClick={(e) => {
														e.preventDefault();
														setEditModal(true);
													}}
												>
													Edit
												</Dropdown.Item>
												<Dropdown.Item
													href="#"
													className="text-danger"
													onClick={() => setPasswordModal(true)}
												>
													Reset Password
												</Dropdown.Item>
											</>
										)}
										{profile?.role?.clients_delete && (
											<Dropdown.Item
												href="#"
												className="text-danger"
												onClick={() => setDeleteModal(true)}
											>
												Delete
											</Dropdown.Item>
										)}
									</Dropdown.Menu>
								</Dropdown>
							) : (
								""
							)}
						</Col>
					</Row>
				</div>
				<Nav variant="tabs" defaultActiveKey={tab} className="mb-20">
					<Nav.Item>
						<Nav.Link
							eventKey="details"
							className="info-tab-item"
							onClick={() => setTab("details")}
						>
							Details
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link
							eventKey="partner"
							className="info-tab-item"
							onClick={() => setTab("partner")}
						>
							Partner
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link
							eventKey="logs"
							className="info-tab-item"
							onClick={() => setTab("logs")}
						>
							Logs
						</Nav.Link>
					</Nav.Item>
				</Nav>
				{tab === "details" && (
					<ClientDetails loading={loading} client={client} />
				)}
				{tab === "partner" && (
					<ClientPartnerDetails loading={loading} client={client} />
				)}
				{tab === "logs" && <InfoLogs loading={loading} data={client} />}
			</Container>
			<Modal
				show={editModal}
				className="info-modal"
				size="lg"
				centered
				onHide={closeEditModal}
			>
				<Form onSubmit={handleSubmit}>
					<Modal.Header>
						<Modal.Title className="text-lg">Edit Client</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Nav variant="tabs" defaultActiveKey={infoTab} className="mb-30">
							<Nav.Item>
								<Nav.Link
									eventKey="details"
									className="info-tab-item light text-sm"
									onClick={() => setInfoTab("details")}
								>
									Details
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link
									eventKey="partner"
									className="info-tab-item light text-sm"
									onClick={() => setInfoTab("partner")}
								>
									Partner
								</Nav.Link>
							</Nav.Item>
						</Nav>
						{infoTab === "details" && (
							<>
								<div className="mb-10">
									<h2 className="text-lg text-medium-gotham mb-0">
										Staff Information
									</h2>
								</div>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Prefix</Form.Label>
										<Form.Control
											autoFocus
											className={`text-sm form-input ${
												errors.includes("prefix") && "border-danger"
											}`}
											value={prefix}
											onChange={(e) => setPrefix(e.target.value)}
										/>
									</Col>
									<Col />
								</Row>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">First Name*</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("firstName") && "border-danger"
											}`}
											value={firstName}
											onChange={(e) => setFirstName(e.target.value)}
										/>
									</Col>
									<Col>
										<Form.Label className="text-sm">Middle Name</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("middleName") && "border-danger"
											}`}
											value={middleName}
											onChange={(e) => setMiddleName(e.target.value)}
										/>
									</Col>
								</Row>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Last Name*</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("lastName") && "border-danger"
											}`}
											value={lastName}
											onChange={(e) => setLastName(e.target.value)}
										/>
									</Col>
									<Col>
										<Form.Label className="text-sm">Suffix</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("suffix") && "border-danger"
											}`}
											value={suffix}
											onChange={(e) => setSuffix(e.target.value)}
										/>
									</Col>
								</Row>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Gender</Form.Label>
										<Form.Select
											className={`text-sm form-input ${
												errors.includes("gender") && "border-danger"
											}`}
											value={gender}
											onChange={(e) => setGender(e.target.value)}
										>
											<option value=""></option>
											<option value="Male">Male</option>
											<option value="Female">Female</option>
											<option value="Other">Other</option>
										</Form.Select>
									</Col>
								</Row>
								<Row className="mb-50">
									<Col>
										<Form.Label className="text-sm">Agency</Form.Label>
										<Select
											isClearable
											defaultValue={agency}
											onChange={setAgency}
											options={agencies}
											menuShouldScrollIntoView={false}
											maxMenuHeight={200}
											placeholder=""
											styles={{
												control: (baseStyles, state) => ({
													...baseStyles,
													borderColor: state.isFocused
														? "#c7883a !important"
														: "#dee2e6 !important",
													boxShadow: "none",
													outline: "none",
													borderRadius: 4,
													fontSize: "0.875rem",
												}),
												option: (baseStyles, state) => ({
													...baseStyles,
													fontSize: ".875rem",
													color: "#050911",
													backgroundColor: state.isFocused
														? "#f6d4aa !important"
														: "#fff",
												}),
											}}
										/>
									</Col>
									<Col>
										<Form.Label className="text-sm">Position</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("position") && "border-danger"
											}`}
											value={position}
											onChange={(e) => setPosition(e.target.value)}
										/>
									</Col>
								</Row>
								<div className="mb-10">
									<h2 className="text-lg text-medium-gotham mb-0">
										Account Information
									</h2>
								</div>
								<Row className="mb-50">
									<Col>
										<Form.Label className="text-sm">Email*</Form.Label>
										<Form.Control
											type="email"
											className={`text-sm form-input ${
												errors.includes("email") && "border-danger"
											}`}
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>
									</Col>
								</Row>
								<div className="mb-10">
									<h2 className="text-lg text-medium-gotham mb-0">
										Contact Information
									</h2>
								</div>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Address</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("address") && "border-danger"
											}`}
											value={address}
											onChange={(e) => setAddress(e.target.value)}
										/>
									</Col>
								</Row>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">
											Village/Apartment
										</Form.Label>
										<Select
											isClearable
											defaultValue={village}
											onChange={setVillage}
											options={villages}
											menuShouldScrollIntoView={false}
											maxMenuHeight={200}
											placeholder=""
											styles={{
												control: (baseStyles, state) => ({
													...baseStyles,
													borderColor: state.isFocused
														? "#c7883a !important"
														: "#dee2e6 !important",
													boxShadow: "none",
													outline: "none",
													borderRadius: 4,
													fontSize: "0.875rem",
												}),
												option: (baseStyles, state) => ({
													...baseStyles,
													fontSize: ".875rem",
													color: "#050911",
													backgroundColor: state.isFocused
														? "#f6d4aa !important"
														: "#fff",
												}),
											}}
										/>
									</Col>
								</Row>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Home Phone #</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("homePhone") && "border-danger"
											}`}
											value={homePhone}
											onChange={(e) => setHomePhone(e.target.value)}
										/>
									</Col>
									<Col>
										<Form.Label className="text-sm">Mobile #</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("msisdn") && "border-danger"
											}`}
											value={msisdn}
											onChange={(e) => setMsisdn(e.target.value)}
										/>
									</Col>
								</Row>
								<Row className="mb-50">
									<Col>
										<Form.Label className="text-sm">Work Phone</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("workPhone") && "border-danger"
											}`}
											value={workPhone}
											onChange={(e) => setWorkPhone(e.target.value)}
										/>
									</Col>
									<Col>
										<Form.Label className="text-sm">Local Ext.</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("localExt") && "border-danger"
											}`}
											value={localExt}
											onChange={(e) => setLocalExt(e.target.value)}
										/>
									</Col>
									<Col>
										<Form.Label className="text-sm">Fax</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("fax") && "border-danger"
											}`}
											value={fax}
											onChange={(e) => setFax(e.target.value)}
										/>
									</Col>
								</Row>
								<div className="mb-10">
									<h2 className="text-lg text-medium-gotham mb-0">
										Miscellaneous
									</h2>
								</div>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Remarks</Form.Label>
										<Form.Control
											as="textarea"
											className={`text-sm form-input ${
												errors.includes("remarks") && "border-danger"
											}`}
											value={remarks}
											onChange={(e) => setRemarks(e.target.value)}
										/>
									</Col>
								</Row>
							</>
						)}
						{infoTab === "partner" && (
							<>
								<div className="mb-10">
									<h2 className="text-lg text-medium-gotham mb-0">
										Partner Information
									</h2>
								</div>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Prefix</Form.Label>
										<Form.Control
											autoFocus
											className={`text-sm form-input ${
												errors.includes("partnerPrefix") && "border-danger"
											}`}
											value={partnerPrefix}
											onChange={(e) => setPartnerPrefix(e.target.value)}
										/>
									</Col>
									<Col />
								</Row>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">First Name</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("partnerFirstName") && "border-danger"
											}`}
											value={partnerFirstName}
											onChange={(e) => setPartnerFirstName(e.target.value)}
										/>
									</Col>
									<Col>
										<Form.Label className="text-sm">Middle Name</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("partnerMiddleName") && "border-danger"
											}`}
											value={partnerMiddleName}
											onChange={(e) => setPartnerMiddleName(e.target.value)}
										/>
									</Col>
								</Row>
								<Row className="mb-50">
									<Col>
										<Form.Label className="text-sm">Last Name</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("partnerLastName") && "border-danger"
											}`}
											value={partnerLastName}
											onChange={(e) => setPartnerLastName(e.target.value)}
										/>
									</Col>
									<Col>
										<Form.Label className="text-sm">Suffix</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("partnerSuffix") && "border-danger"
											}`}
											value={partnerSuffix}
											onChange={(e) => setPartnerSuffix(e.target.value)}
										/>
									</Col>
								</Row>
								<div className="mb-10">
									<h2 className="text-lg text-medium-gotham mb-0">
										Partner Contact Information
									</h2>
								</div>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Email</Form.Label>
										<ReactTags
											tags={partnerEmails}
											delimiters={emailDelimiters}
											handleInputBlur={handlePartnerEmailBlur}
											handleDelete={handleDeletePartnerEmail}
											handleAddition={handleNewPartnerEmail}
											inputFieldPosition="bottom"
											allowDragDrop={false}
											placeholder=""
											autofocus={false}
											classNames={{
												tagInput: "full",
												tagInputField: `full form-control form-input text-sm ${
													errors.includes("partnerEmails") && "border-danger"
												}`,
												tag: "tag-item text-sm bg-green text-white rounded-4",
												remove: "tag-remove-btn text-white flex-column",
											}}
										/>
									</Col>
								</Row>
								<Row className="mb-20">
									<Col>
										<Form.Label className="text-sm">Mobile #</Form.Label>
										<Form.Control
											className={`text-sm form-input ${
												errors.includes("partnerMsisdn") && "border-danger"
											}`}
											value={partnerMsisdn}
											onChange={(e) => setPartnerMsisdn(e.target.value)}
										/>
									</Col>
								</Row>
							</>
						)}
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="btn-sm action-btn text-sm me-2"
							onClick={closeEditModal}
						>
							Close
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="btn-sm action-btn text-sm btn-default"
							disabled={actionLoading}
						>
							{actionLoading ? (
								<Spinner className="btn-loader text-white" />
							) : (
								"Save Changes"
							)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter mb-15">Delete Client</h2>
					<p className="text-sm opacity-7">
						Are you sure you want to delete this client? This action cannot be
						undone.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => setDeleteModal(false)}
					>
						Cancel
					</Button>
					<Button
						variant="danger"
						className="action-btn btn-sm text-medium-inter text-sm flex-column"
						disabled={deleteLoading}
						onClick={deleteClient}
					>
						{deleteLoading ? (
							<Spinner className="btn-loader text-white" />
						) : (
							"Delete Client"
						)}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={passwordModal}
				className="overlay-modal"
				backdropClassName="overlay-modal-backdrop"
				onHide={() => closePasswordModal()}
			>
				<Form onSubmit={resetPassword}>
					<Modal.Header>
						<Modal.Title className="text-lg">Reset Password</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className="mb-20">
							<Col>
								<Form.Label>Temporary Password</Form.Label>
								<p className="label-md">
									The client will be prompted to change their password upon log
									in.
								</p>
								<Form.Control
									required
									autoFocus
									type="password"
									className="form-input"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-secondary"
							className="modal-btn btn-sm dismiss-btn text-sm me-2"
							onClick={() => closePasswordModal()}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="btn-default btn-sm action-btn text-sm me-2 flex-row-center"
							disabled={actionLoading}
						>
							{actionLoading ? (
								<Spinner className="btn-loader text-white" />
							) : (
								"Reset Password"
							)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default Client;
