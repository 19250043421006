import { React, useContext, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { SessionProvider, SessionContext } from "./context/SessionContext";
import { ProfileProvider, ProfileContext } from "./context/ProfileContext";
import { ClientProvider, ClientContext } from "./context/ClientContext";
import {
	ClientProfileProvider,
	ClientProfileContext,
} from "./context/ClientProfileContext";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "remixicon/fonts/remixicon.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./index.css";

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import NoPage from "./pages/NoPage";
import Items from "./pages/Items";
import Item from "./pages/Item";
import Clients from "./pages/Clients";
import Client from "./pages/Client";
import Contractors from "./pages/Contractors";
import Contractor from "./pages/Contractor";
import WorkOrders from "./pages/WorkOrders";
import WorkOrder from "./pages/WorkOrder";

import ClientLanding from "./pages/ClientLanding";
import ClientLayout from "./pages/ClientLayout";
import ClientHome from "./pages/ClientHome";
import ClientLogin from "./pages/ClientLogin";
import ClientWorkOrder from "./pages/ClientWorkOrder";
import ClientLogout from "./pages/ClientLogout";

import Roles from "./pages/Roles";
import Role from "./pages/Role";
import Users from "./pages/Users";
import User from "./pages/User";

const root = createRoot(document.getElementById("root"));

export default function App() {
	const [session] = useContext(SessionContext);
	const [profile, setProfile] = useContext(ProfileContext);
	const [clientSession] = useContext(ClientContext);
	const [clientProfile, setClientProfile] = useContext(ClientProfileContext);

	const getProfile = async () => {
		const requestOptions = {
			headers: {
				method: "GET",
				"X-Auth-Token": session,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Can't get user.");
		} else {
			setProfile(data.user);
		}
	};

	const getClientProfile = async () => {
		const requestOptions = {
			headers: {
				method: "GET",
				"X-Auth-Token": clientSession,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/clients/me`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Can't get client.");
		} else {
			setClientProfile(data.client);
		}
	};

	useEffect(() => {
		if (session !== null) {
			getProfile();
		}
	}, [, session]);

	useEffect(() => {
		if (clientSession !== null) {
			getClientProfile();
		}
	}, [, clientSession]);

	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="property/*"
					element={!session ? <Navigate to="/property/login" /> : <Layout />}
				>
					<Route index element={<Home profile={profile} />} />
					<Route path="work-orders" element={<WorkOrders />} />
					<Route path="work-orders/:id" element={<WorkOrder />} />
					<Route path="inventory/items" element={<Items />} />
					<Route path="inventory/items/:id" element={<Item />} />
					<Route path="clients" element={<Clients />} />
					<Route path="clients/:id" element={<Client />} />
					<Route path="contractors" element={<Contractors />} />
					<Route path="contractors/:id" element={<Contractor />} />
					<Route path="users" element={<Users />} />
					<Route path="users/:id" element={<User />} />
					<Route path="roles" element={<Roles />} />
					<Route path="roles/:id" element={<Role />} />
				</Route>
				<Route path="property/login" element={<Login />} />
				<Route path="property/logout" element={<Logout />} />

				<Route
					path="/*"
					element={!clientSession ? <Navigate to="login" /> : <ClientLayout />}
				>
					<Route index element={<ClientLanding />} />
					<Route path="work-orders" element={<ClientHome />} />
					<Route path="work-orders/:id" element={<ClientWorkOrder />} />
				</Route>
				<Route path="logout" element={<ClientLogout />} />
				<Route path="login" element={<ClientLogin />} />
				<Route path="*" element={<NoPage />} />
			</Routes>
		</BrowserRouter>
	);
}

root.render(
	<SessionProvider>
		<ProfileProvider>
			<ClientProvider>
				<ClientProfileProvider>
					<App />
				</ClientProfileProvider>
			</ClientProvider>
		</ProfileProvider>
	</SessionProvider>
);
